// Optional captions

.carousel-caption {
    background: $black;
    background: linear-gradient(0deg, rgba($black, 0.7) 0%, rgba($white, 0) 100%);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: $grid-gutter-width/2;
    top: 80%;
    // styles from node_modules/bootstrap/scss/carousel
    position: absolute;
    right: ((100% - $carousel-caption-width) / 2);
    left: ((100% - $carousel-caption-width) / 2);
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $carousel-caption-color;
    text-align: center;
}

.carousel-copyright {
    display: block;
    font-size: 1.2rem;
    text-align: left;
}
