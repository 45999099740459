.contact-panel {

    &__header {
        border-bottom: 0.1rem solid $gray-50;
    }

    &__btn {
        background: none;
        border: 0;
        color: $white;
        display: block;
        font-size: 2rem;
        font-weight: $font-weight-light;
        line-height: 1.200;
        padding: 1.8rem 3rem 2rem 0;
        position: relative;
        text-align: left;
        text-transform: uppercase;
        white-space: normal;
        width: 100%;

        @include media-breakpoint-up(xl) {
            padding-bottom: 2.3rem;
            padding-top: 2.1rem;
            font-size: 2.4rem;
            line-height: 0.875; // =21px
        }

        &:not(.collapsed) {
            .contact-panel__icon {
                transform: rotate(0);
            }
        }
    }

    &__icon {
        display: block;
        height: 2.4rem;
        margin-right: 0 !important;
        margin-top: -1.2rem;
        padding-left: 0.1rem;
        position: absolute;
        right: 0;
        top: 50%;
        width: 2.6rem;
        transform: rotate(45deg);
    }

    &__content {
        padding: 3.6rem 0 0;

        @include media-breakpoint-up(xl) {
            padding-top: 4.8rem;
        }
    }

    &__brand {
        text-align: center;

        &:not(:last-child) {
            margin-bottom: 4.8rem;

            @include media-breakpoint-up(xl) {
                margin-bottom: 6rem;
            }
        }
    }

    &__branding {
        margin: 0;

        & + .contact-panel__division {
            margin-top: 1.9rem;

            @include media-breakpoint-up(xl) {
                margin-top: 3.4rem;
            }
        }

        & + .row {
            margin-top: 3.6rem;

            @include media-breakpoint-up(xl) {
                margin-top: 5.4rem;
            }
        }
    }

    &__logo {
        margin: 0 1.2rem;

        &--m6 {
            max-width: 5.6rem;
        }
        &--w9 {
            max-width: 7.8rem;
        }
        &--6ter {
            max-width: 8.2rem;
        }
        &--teva {
            max-width: 4.8rem;
        }
        &--paris-premiere {
            max-width: 10.7rem;
        }
        &--serieclub {
            max-width: 10.6rem;
        }
    }

    &__division {
        &:not(:last-child) {
            margin-bottom: 3.5rem;

            @include media-breakpoint-up(xl) {
                margin-bottom: 4.6rem;
            }
        }
    }
}
