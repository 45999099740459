// Colors
$gray-5:    #0d0d0d; // (sat9)
$gray-9:    #171717; // (sat9)
$gray-11:   #1D1D1D; // Fond du site
$gray-16:   #292929; // Fond du site
$gray-20:   #333333; // Fond footer
$gray-27:   #454545; // Couleur texte popin
$gray-29:   #4A4A4A; // Gris des tags article (sat4)
$gray-31:   #4F4F4F; // bordure image lazyload (sat3)
$gray-44:   #707070;
$gray-50:   #7F7F7F;
$gray-75:   #BFBFBF;
$gray-92:   #EBEBEB; // bordure image lazyload

$brand-color: #F0000D;
$brand-color-hover: #D6000B;


$facebook-color: #3b5998;
$twitter-color: #000;
$instagram-color: #f56040;

// Grid breakpoints
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700|Roboto+Condensed:300,400,700|Lato:300,400,700');