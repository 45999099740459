.presskit-list {

    &__item {
        &:not(:last-child) {
            margin-bottom: 2 * $font-size-base * $line-height-base;
        }

        &:nth-last-child(-n+3) {
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }
    }
}
