/**
 * NAV SITE
 */

.nav-site {
    font-size: 1.8rem;
    font-family: $headings-font-family;
    font-weight: $font-weight-light;
    line-height: 1.208; // =29px
    text-transform: uppercase;

    @include media-breakpoint-down(xl) {
        padding-left: 3rem;
    }

    @include media-breakpoint-down(xs) {
        padding-left: 2rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }

    @include media-breakpoint-up(xl) {
        margin-right: 4.2rem;
    }

    @include media-breakpoint-up(xxl) {
        margin-right: 2.1rem;
    }

    &__list {
        @include media-breakpoint-up(xxl) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    &__list-item {
        @include media-breakpoint-down(xl) {

            & + #{&} {
                margin-top: 3rem;
            }
        }
    }

    &__link {
        transition: .2s color;
        color: $gray-75;
        display: inline-block;
        padding: 1rem 2.1rem;

        @include media-breakpoint-down(3xl) {
            padding: 1rem 0.75rem;
        }

        @include media-breakpoint-down(xxl) {
            padding: 1rem .5rem;
        }

        @include media-breakpoint-down(xl) {
            padding: 1rem 0;
        }

        &--active {
            font-weight: $font-weight-bold;
            color: $white;
        }

        &:hover {
            color: $white;
        }
    }
}
