.swiper-weekdays {
    background: $gray-11;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    color: $gray-44;
    border-top: 2px solid $gray-16;

    .active {
        @extend .shadow;
        color: $white;
        background: $gray-16;
        border-bottom: 2px solid $brand-color;
    }

    &__bullet {
        outline: none;

        &:not(:active) {
            transition: .1s background-color ease-in;
        }

        &:active {
            background: $gray-20;
        }
    }

    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.swiper-back-to-top {
    background: $brand-color;
    line-height: 2;
    width: 58px;
    height: 58px;
    margin: 0 auto 20px;
    display: block;
    border-radius: 50%;
    opacity: 0;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}