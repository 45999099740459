// Headings

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    color: $white;
    text-transform: uppercase;
}

h1, .h1 {
    line-height: 1.1em;
    margin-top: 0.6744186em;
    margin-bottom: 0.6744186em;
    font-weight: $font-weight-bold;

    @include media-breakpoint-down(sm) {
        &-sm {
            font-size: $h1-font-size-sm;
        }
    }
}
h2, .h2 {
    line-height: 1.2em;
    margin-top: 0.90625em;
    margin-bottom: 0.90625em;
    font-weight: $font-weight-light;

    @include media-breakpoint-down(sm) {
        &-sm {
            font-size: $h2-font-size-sm;
        }
    }
}
h3, .h3 {
    line-height: 1.20833333em;
    margin-top: 1.20833333em;
    margin-bottom: 0em;
    font-weight: $font-weight-bold;
}
h4, .h4 {
    line-height: 1.333em;
    margin-top: 1.61111111em;
    margin-bottom: 0em;
    font-weight: $font-weight-bold;
    letter-spacing: .02rem;
}
h5, .h5 {
    line-height: 1.333em;
    margin-top: 1.61111111em;
    margin-bottom: 0em;
    font-weight: $font-weight-bold;
    letter-spacing: .02rem;
}
h6, .h6 {
    line-height: 1.333em;
    margin-top: 1.61111111em;
    margin-bottom: 0em;
    font-weight: $font-weight-bold;
    letter-spacing: .02rem;
}
