.cover {
    width: 100%;
    height: 100vh;
    position: fixed;

    &.cover-fallback {
        background-size: cover;
        background-position: center center;

        img {
            opacity: 0;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        opacity: 0;
    }
    
    // Cover -- default case
    &:not(.cover--error):not(.cover--success) {
        &::after {
            background: rgba(0,0,0,.5);
            @include bg-gradient($brand-color);
        }
        
        img {
            filter: grayscale(0.7);
            object-position: center center;
        }
    }

    // Cover -- in errors pages
    &--error {
        img {
            object-position: 50% 14%;
        }

        &::after {
            @include bg-gradient-error($brand-color);
        }
    }

    &--success {
        img {
            object-position: left 50% top 34%;
        }

        &::after {
            @include bg-gradient-error($brand-color);
        }
    }
}