.headband-presskit{

   &__title{
      font-family: Lato, Arial, sans-serif;
      font-size: 40px;
      text-transform: uppercase;
      color: #FFF;

      @include media-breakpoint-down(sm) {
         font-size: 32px;
      }
   }

   &__header{
      gap: 20px;

      img{
         height: 50px;
         width: 100px;
      }
   }

   &__btn{
      background-color: #FFF;
      border-radius: 5px;
      height: 56px;
      gap: 8px;
      font-size: 20px;
      padding: 0px 24px 0px 16px;
      color: #000;
      &:hover,
      &:focus{
         color: #000;
      }
   }

   &__icon{
      font-size: 30px;
      font-weight: 500;
   }

}
