.press-contact {
    max-width: 19rem;

    @include media-breakpoint-down(md) {
        margin: 0 auto;
        text-align: center;
    }

    &__name {
        margin-bottom: 1.3rem;
    }
}
