$easeInOutExpo: cubic-bezier(1, 0, 0, 1);

.swiper-carousel {

    &:hover {
        .swiper-button-prev {
            opacity: 1;
            left: 15px;
        }
        .swiper-button-next {
            opacity: 1;
            right: 15px;
        }
    }

    &__nav {
        &__btn {
            pointer-events: none;
            background: rgba(127,127,127,.2);
            font-size: 2.4rem;
            text-align: center;
            line-height: 56px;
            width: 56px;
            height: 56px;
            border-radius: 50%;
        }
    }
}

// Swiper existing components
.swiper-button-prev,
.swiper-button-next {
    background-image: none;
    width: auto;
    transition: all .3s $easeInOutExpo;
    opacity: 0;
}

.swiper-button-prev {
    left: -100px;
}
.swiper-button-next {
    right: -100px;
}

.swiper-pagination-fraction {
    font-size: $font-size-sm;
    text-align: right;
    padding: 0 15px;
    bottom: 1.2rem;
}