/**
 * NAV BRANDS
 */

.nav-brands {
    @include media-breakpoint-up(xxl) {
        margin-right: auto;
    }

    &__list {

        @include media-breakpoint-up(xxl) {
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }
    }

    &__list-item {
        height: 7.5rem;
        width: 8rem;

        @include media-breakpoint-down(xl) {
            &:not(:last-child) {
                margin-bottom: .5rem;
            }
        }

        @include media-breakpoint-up(md) {
            height: auto;
            width: auto;
        }

        //@include media-breakpoint-up(xl) {
        //    height: 8.5rem;
        //    width: 8.5rem;
        //}

        @media (min-width: 1425px) {
            height: auto;
            width: auto;
        }

        //@media (min-width: 1600px) and (max-width: 1665px) {
        //    height: 8.5rem;
        //    width: 8.5rem;
        //}

    }

    &__link {
        position: relative;
        padding: 0 1.5rem;

        @include media-breakpoint-up(xxl) {
            align-items: center;
            display: flex;
            height: 100%;
        }

        @include media-breakpoint-down(3xl) {
            padding: 1rem 0.75rem;
        }

        @include media-breakpoint-down(xxl) {
            padding: 1rem .5rem;
        }

        @include media-breakpoint-down(xl) {
            background: $gray-9;
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            margin: auto;
        }


        .active & {
            @include media-breakpoint-down(xl) {
                background: $gray-9;

                &:before {
                    content: ' ';
                    display: block;
                    width: calc(100% + 2px);
                    height: calc(100% + 2px);
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    z-index: -1;
                    @include gradient-x(transparentize($brand-color, .8), transparentize($brand-color, .2));
                }
            }
        }

        // état actif
        .active & {

            &:after {
                background: $black;
                bottom: .5rem;
                content: "Pro";
                display: inline-block;
                font-size: 1rem;
                font-weight: $font-weight-bold;
                left: 50%;
                line-height: normal;
                padding: 0.2rem 0.4rem 0;
                position: absolute;
                text-transform: uppercase;
                transform: translateX(-50%);

                @include media-breakpoint-up(xxl) {
                    bottom: -2.3rem;
                }
            }
        }
    }
}
