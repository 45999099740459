.last-minute {
    background: $gray-92;
    color: $gray-27;
    font-size: 1.8rem;
    line-height: 1.389; // =25px
    padding: 6.5rem 3rem 6.3rem;

    &__container {
        margin: 0 auto;
        max-width: 70rem;
    }

    &__title {
        color: currentColor;
        padding-bottom: 1.5rem;
        position: relative;
        text-align: center;

        @include media-breakpoint-up(md) {
            font-size: 4.3rem;
            line-height: 1.209; // =52px
            padding-bottom: 1.1rem;
        }

        &:after {
            background: $brand-color;
            bottom: 0;
            content: "";
            display: block;
            height: 0.1rem;
            left: 50%;
            transform: translate(-50%);
            position: absolute;
            width: 6.4rem;
        }
    }

    &__head,
    &__warning,
    &__subtitle {
        text-transform: uppercase;
    }

    &__head {
        margin-bottom: 2.9rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 2.3rem;
        }
    }

    &__subtitle,
    &__warning,
    &__download-link {
        font-weight: $font-weight-bold;
    }

    &__subtitle {
        line-height: 1.278; // =23px
        margin-bottom: 0.3rem;
    }

    &__date {
        font-size: 1.6rem;
        font-weight: $font-weight-light;
        line-height: 1.125; // =18px
    }

    &__date,
    &__subtitle {
        @include media-breakpoint-up(md) {
            font-size: 2.6rem;
            line-height: 1.231; // =32px
        }
    }

    &__warning,
    &__download-link {

        & > * {
            display: table-cell;
            vertical-align: middle;
        }
    }

    &__warning,
    &__download {

        &-text {
            padding-left: 0.8rem;
        }
    }

    &__warning {
        font-size: 1.5rem;
        line-height: 1.200;
        margin-bottom: 0.9rem;

        @include media-breakpoint-up(md) {
            font-size: 1.7rem;
            line-height: 1.706; // =29px
            margin-bottom: 0.7rem;
        }

        &-icon {
            font-size: 2rem;
            line-height: 1;
            margin-right: 0.4rem;
            position: relative;
            top: -0.1rem;
        }
    }

    &__desc {
        margin-bottom: 1.9rem;
    }

    &__download-link {
        font-size: 1.4rem;
        display: block;
        line-height: 1.286; // =18px
    }
}
