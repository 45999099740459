.article {

    &__header,
    &__subheader {
        display: flex;
        flex-direction: column;
    }

    &__header {

        &-info {
            margin: 0 0 0.7rem;
        }
    }

    &__title {
        margin-top: 0;
    }

    &__subtitle {
        margin-top: 0;
        text-transform: none;
        font-weight: bold;
    }

    &__visual {
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 2.9rem;
        }
    }

    &__content {
        color: $paragraph-color;

        p {
            white-space: pre-line;
        }
    }

    &__container {
        overflow: hidden;
        width: 100%;
    }

    &__main {
        @include media-breakpoint-up(lg) {
            float: left;
        }
    }

    &__aside {
        @include media-breakpoint-up(lg) {
            float: right;
        }
    }

    div#{&}__hat {
        font-size: 1.6rem;
        margin: 0;

        color: $gray-75;
    }

    p#{&}__cta {
        max-width: 20rem;
        white-space: normal;
        margin-bottom: $paragraph-margin;
    }
}
