.push-emissions {
    padding-top: 1rem;
    position: relative;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        float: left;
    }

    &__list-item {
        &:not(:last-child) {
            margin-bottom: $paragraph-margin;
        }
    }

    &__title {
        display: inline-block;
    }

    &__link {

        &:active,
        &:focus,
        &:hover {
            text-decoration: none;

            .push-emissions__desc {
                text-decoration: underline;
            }
        }
    }

    &__desc {
        font-weight: $font-weight-light;
    }

    &:before {
        @include media-breakpoint-up(lg) {
            background: $gray-50;
            content: "";
            height: 0.1rem;
            left: $grid-gutter-width/2;
            position: absolute;
            top: 0;
            width: 8.7rem;
        }
    }
}
