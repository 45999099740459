.program {

    &-tools {

        &__cta {
            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
        }
    }
}
