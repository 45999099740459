.account {
    font-size: 1.7rem;

    &__section {

        &-title {
            position: relative;
            margin-bottom: 3rem;

            &:after {
                background: $gray-50;
                content: "";
                display: block;
                height: 0.1rem;
                margin-top: 1.9rem;
                width: 8.8rem;
            }
        }

        &-subtitle {
            font-size: 1.8rem;
            font-weight: $font-weight-light;
            margin-bottom: 2.3rem;
        }
    }

    &__contact {

        &:active,
        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &__alerts,
    &__submit {
        text-align: center;
    }

    &__submit {

        @include media-breakpoint-up(sm) {
            text-align: left;
        }
        @include media-breakpoint-up(md) {
            text-align: right;
        }

        &-btn {
            min-width: 19.8rem;
        }
    }

    strong {
        text-transform: uppercase;
    }
}
