.tutorial {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 50px;

    @include gradient-radial(rgba($black, 0.3), transparent);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: $zindex-dropdown;

    opacity: 0;
    animation: pulse 2s ease 1s forwards;
}

@keyframes pulse {
    0%   {opacity: 0;}
    25%  {opacity: 1;}
    50%  {opacity: 0.3;}
    75%  {opacity: 1;}
    100% {opacity: 0;}
}