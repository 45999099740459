@mixin hover($color) {
    &:hover {
        background: adjust_color($color, $lightness: -5%);
    }
}

@mixin activeStyle($color) {
    &:not(:empty) {
        background: $color;
        @include hover($color);
    }
}

.social-heart {
    min-height: 100px;
    display: flex;
    justify-content: center;

    &__item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 51px;
        height: 51px;
        background: $gray-44;
        transform: rotate(-45deg);
        border-radius: 5px;
        line-height: 0;
        transition: .2s background-color;
        background: $gray-16;

        span {
            transform: rotate(45deg);
        }

        &:nth-child(1) {
            font-size: 20px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            transform: translate(10px, 0) rotate(-45deg);
            @include activeStyle($facebook-color);
        }

        &:nth-child(2) {
            font-size: 24px;
            transform: translate(0, 40px) rotate(-45deg);
            @include activeStyle($twitter-color);
        }

        &:nth-child(3) {
            font-size: 26px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            transform: translate(-10px, 0) rotate(-45deg);
            @include activeStyle($instagram-color);
        }
    }
}