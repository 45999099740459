body {
    display: flex;
    flex-direction: column;
}

.page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100vh;
    position: relative;
    background-image: linear-gradient(to bottom,rgba(29,29,29,.5) 0%,rgba(23,23,23,.95) 80%);
}

.site-container {
    @include media-breakpoint-up(3xl) {
        padding-left: 10rem;
        padding-right: 10rem;
    }
}

.site-content {
    padding-bottom: 7rem;
    padding-top: 8.2rem;

    @include media-breakpoint-up(md) {
        flex-grow: 1;
    }
    @include media-breakpoint-up(md) {
        padding-bottom: 15rem;
        padding-top: 10rem;
    }
}

.content-main:not(:last-child) {
    @include media-breakpoint-down(lg) {
        margin-bottom: 6rem;
    }
}

.embed-responsive {
    background-color: rgba(10,10,10, 0.2);

    &:before {
        background: url(../img/logo-m6.svg) no-repeat center;
        background-size: 50% 50%;
        filter: grayscale(100%);
        height: 100% !important;
        opacity: 0.25;
        width: 100% !important;
    }
}
img.lazy,
img.swiper-lazy {
    opacity: 0;
    border: 0;
}


/**
 * Page - Presskit article
 */

.page--presskit-article {

    .article__aside {
        @include media-breakpoint-up(lg) {
            padding-top: 7rem;
        }
    }
}
