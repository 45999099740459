.contact-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-weight: $font-weight-bold;
    justify-content: space-between;
    text-align: center;
    text-transform: uppercase;

    &__img,
    &__visual {
        display: inline-block;
    }

    &__visual {

        &-wrap {
            margin: 0 auto;
            max-width: 14.4rem;
        }

        &--placeholder {

            .embed-responsive {

                &,
                &::before {
                    background: none;
                }
            }

            img.embed-responsive-item {
                border-radius: 0;
            }
        }
    }

    &__img {
        border-radius: 50%;
    }

    &__title {
        line-height: 1.2;
        margin-top: 24px;
    }

    &__job {
        margin-top: 7px;
        font-weight: $font-weight-bold;
        font-size: 12px;
    }

    .button {
        max-width: 21rem;

        &,
        &__icon {
            border-color: $white;
        }
    }

    &__phone {
        margin-bottom: 0;
    }
}
