.nav-week {
    font-size: 2rem;
    margin: 0 auto 4.3rem;
    max-width: 83rem;
    position: relative;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        font-size: 2.6rem;
        line-height: 1.269; // =33px
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 7.2rem;
    }

    // List
    &__list {
        $border-color: transparentize(adjust_hue($brand-color, -10), .6);
        border-bottom: 0.2rem solid $border-color;
        display: flex;
        justify-content: space-between;
    }

    // Links
    &__link {
        border-bottom: 0.4rem solid transparent;
        display: block;
        font-weight: $font-weight-thin;
        line-height: normal;
        padding: 1rem 0 .5rem;

        // toutes les semaines sauf la semaine courante
        &:not(.current-week) {
            .nav-week__text {

                @include media-breakpoint-down(sm) {
                    @include sr-only;
                }
            }
        }

        // semaine courante
        &.current-week {
            @include media-breakpoint-down(md) {
                font-weight: $font-weight-normal;
            }
        }

        // état active
        &.active {
            font-weight: $font-weight-bold;
        }

        .js-navweek-slider {
            display: block;
            height: 2px;
            background: $brand-color;
            position: absolute;
            bottom: 0;
        }
    }

    abbr {
        pointer-events: none;
        text-decoration: none;
    }
}
