.page--error {
    .site-content {
        display: flex;
        min-height: calc(100vh - 5.2rem);

        @include media-breakpoint-up(xxl) {
            min-height: calc(100vh - 9.6rem);
        }
    }
}

.errorContent {
    margin: auto;

    &__title,
    &__text  {
        font-family: $headings-font-family;
    }

    &__title {
        font-size: 8.2rem;
        font-weight: $font-weight-light;
        margin-bottom: 1.2rem;
    }

    &__text {
        font-size: 2.6rem;
        font-weight: $font-weight-bold;
        line-height: 1.188;
        letter-spacing: 0.1rem;
        margin-bottom: 4.1rem;
        text-transform: uppercase;
        
        @include media-breakpoint-up(md) {
            font-size: 3.2rem;
        }
    }

    &__link {
        max-width: 29rem;
    }
}