/**
 * LAST MINUTE ALERT
 */

%anim-element {
    border-radius: 50%;
    display: block;
    height: 3.2rem;
    position: absolute;
    width: 3.2rem;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.last-minute-alert {

    &__info {
        @include media-breakpoint-up(xl) {
            position: relative;
        }
    }

    &__btn {
        border: 0.2rem solid $gray-29;
        border-radius: 50%;
        color: $gray-50;
        cursor: pointer;
        font-size: 2rem;
        height: 4.2rem;
        line-height: 1; // pour l'icône
        padding: 0;
        position: relative;
        width: 4.2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @at-root .active #{&} {
            border-color: $brand-color;
            color: $white;
        }

        &:after {
            @extend %anim-element;
            background: $gray-29;
            content: "";

            @at-root .active #{&} {
                @extend %anim-element;
                background: $brand-color;
            }
        }

        &[data-notifications] {

            &:before {
                align-items: center;
                background: $brand-color;
                border: 0.2rem solid $gray-11;
                border-radius: 50%;
                content: attr(data-notifications);
                display: flex;
                font-size: 1rem;
                font-weight: $font-weight-bold;
                height: 2.1rem;
                justify-content: center;
                position: absolute;
                right: 0;
                top: -0.7rem;
                transform: translateX(50%);
                width: 2.1rem;
                z-index: 2;
                color: $white;
            }
        }
    }

    .js-ripple {
        @extend %anim-element;
        $color: change_color(adjust_hue($brand-color, -15), $lightness: 60%);

        @at-root .active#{&} {
            background: $color;
        }
    }

    &__icon {
        display: inline-block;
        position: relative;
        z-index: 1;
        margin: 0 !important;
    }

    [data-content="popover"] {
        display: none;
    }
}


/**
 * LAST MINUTE BOX
 */

.last-minute-box {
    border: 0;
    border-radius: 0;
    color: $gray-27;
    font-size: 1.7rem;
    left: auto;
    line-height: 1.200;
    padding: 4rem 1.5rem 3rem;
    right: 0;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    $scollerSpacing: $spacer * 3;
    &__scroller {
        position: relative;

        &::before,
        &::after {
            content: ' ';
            display: block;
            height: $scollerSpacing;
            width: 100%;
            position: absolute;
        }

        &:before {
            @include gradient-y($gray-92, transparentize($gray-92, 1));
            top: 0;
        }

        &::after {
            @include gradient-y(transparentize($gray-92, 1), $gray-92);
            bottom: 0;
        }
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: $scollerSpacing 0;
        overflow-y: scroll;
        max-height: 350px;
        scrollbar-color: transparent;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__list-item {
        overflow: hidden; // Fix for Edge16 : bug list-style-type

        &:not(:last-child) {
            margin-bottom: 3rem;
        }
    }

    &__link {
        float: left; // Fix for Edge16 : bug space top
        width: 100%; // Fix for Edge16 : bug space top

        &:focus,
        &:hover {
            text-decoration: none;

            .last-minute-box__subtitle {
                text-decoration: underline;
            }
        }
    }

    &__title {
        color: currentColor;
        font-size: 3.2rem;
        font-weight: $font-weight-light;
        line-height: 0.906; // =29px
        margin: 0 auto $spacer;
        padding: 0 0 $spacer * 2;
        position: relative;
        text-align: center;

        &:after {
            background: $brand-color;
            bottom: 0;
            content: "";
            display: block;
            height: 0.1rem;
            left: 50%;
            transform: translate(-50%);
            position: absolute;
            width: 8rem;
        }
    }

    &__subtitle,
    &__warning {
        font-weight: $font-weight-bold;
    }

    &__subtitle {
        @include media-breakpoint-up(md) {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__date {
        font-weight: $font-weight-light;
    }

    &__desc,
    &__warning {
        display: flex;
        font-size: 1.5rem;

        &-text {
            display: block;
            padding-left: 0.6rem;
            padding-bottom: 0.2rem;
            line-height: 1;
        }
    }

    &__warning {
        align-items: flex-end;

        &-text {
            @include media-breakpoint-down(xs) {
                max-width: 100%;
                line-height: 1.1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    &__desc {
        text-transform: none;

        &-text {
            padding-top: 0.5rem;
        }
    }

    &__icon {
        font-size: 2rem;
        line-height: 0;
    }
}
