@if $enable-print-styles {
    @media print {

        body,
        .badge,
        .btn-outline-light,
        .contact-panel__btn,
        article.last-minute,
        h1, h2, h3, h4, h5, h6,
        .h1, .h2, .h3, .h4, .h5, .h6,
        .press-contact a.btn-outline-theme {
            color: $black !important;
            font-family: Arial, Helvetica, sans-serif;
        }

        .btn-theme,
        .site-footer,
        .site-header,
        .aside-twitter,
        .push-emissions,
        .swiper-container,
        .btn-outline-light,
        .toolbox .btn-outline-theme,
        .press-contact a.btn-outline-theme:nth-child(2) {
            display: none !important;
        }

        .site-header,
        .site-content {
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }

        a {
            &:not(.btn) {
                text-decoration: none;
            }
        }

        // Page Article
        .article__aside {
            display: flex;
            justify-content: center;
        }

        .aside-block {
            width: 50%;
            
            &__title {
                &:first-of-type {
                    margin-top: 0;
                }
            }

            &.program-tools {
                display: none;
            }
        }

        // Page Article Dernière minute
        .page--last-minute {
            .mb-8 {
                margin-bottom: 0 !important;
            }
        }

        article.last-minute {
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .press-contact,
        .last-minute__container {
            border: 0 !important;
            margin: 0 !important;
            text-align: left !important;
        }

        .press-contact {
            &__title,
            &__name {
                margin-bottom: 0 !important;   
            }
            
            a.btn-outline-theme {
                border: 0;
                padding: 0;
            }
        }

        // Page Contact
        .contact-panel {
            
            &__collapse {
                display: block !important;
            }
        }

        .btn-outline-light {
            border-color: $black;
            border-radius: $btn-border-radius;
        }
    }
}