.popover {
    box-shadow: $popover-box-shadow;

    &-header {
        margin: 0;
        border-bottom-color: $popover-border-color;
    }

    &-backdrop {
        background: #3b5998;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1000;
    }

    // Popover LastMinute
    &--lastminute {
        background: $gray-92;
        border-radius: 0;
        border: 0;
        max-width: 95%;
        
        @include media-breakpoint-up(sm) {
            max-width: 46.8rem;
        }

        .arrow {
            &:after {
                display: none;
            }
            &:before {
                border-bottom-color: $white;
                top: 1px;
            }
        }
    }
}

.popover-custom {

    &__title {
        font-weight: bold;
    }

    &__img {
        margin: -1rem -1rem 1rem;
        min-width: $popover-max-width;

        img {
            width: 100%;
        }
    }

    &__tags {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: .5rem;
        align-items: center;

        &:last-child {
            margin: 0;
        }
    }

    &__details {
        font-style: italic;
    }

    &__taglist {
        margin-bottom: 1rem;
    }
}