.aside-block {
    border-bottom: 0.1rem solid $gray-44;
    border-top: 0.1rem solid $gray-44;
    font-size: 1.5rem;
    padding: 3rem 0;

    @include media-breakpoint-down(md) {
        text-align: center;
    }

    &:not(:only-child) {
        @include media-breakpoint-up(lg) {
            border-bottom: 0;
        }

        &:first-child {
            @include media-breakpoint-up(lg) {
                border-top: 0;
                padding-top: 0;
            }
        }

        &:last-child {
            @include media-breakpoint-up(lg) {
                padding-bottom: 0;
            }
        }
    }

    &__title {
        font-size: 1.5rem;
        margin-bottom: .5em;

        &:first-child {
            margin-top: 0;
        }
    }

    &__text {
        color: $paragraph-color;
    }
}
