
.logo {
    display: block;
    transition: 0.15s opacity;
    opacity: 0;

    @at-root .active #{&},
    .nav-brands__link:hover #{&} {
        opacity: 1;
    }

    &:not(.logo--white) {
        left: 50%;
        position: absolute;
        top: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        //max-width: calc(100% - 4.2rem);
        //
        //@include media-breakpoint-down(xl) {
        //    max-width: calc(100% - 3rem);
        //}
    }

    &--white {
        opacity: 0.3;
        position: relative;
        z-index: 2;

        @at-root .active #{&},
        .nav-brands__link:hover #{&} {
            opacity: 0;
        }
    }

    &--m6 {
        height: 3.4rem;
        width: 4rem;
    }

    &--w9 {
        height: 3.1rem;
        width: 7.4rem;
    }

    &--6ter {
        height: 4rem;
        width: 6rem;
    }

    &--teva {
        height: 4rem;
        width: 4rem;
    }

    &--paris-premiere {
        height: 2.6rem;
        width: 10rem;
    }

    &--serieclub {
        height: 3rem;
        width: 11rem;
    }

    &--gulli {
        height: 4rem;
        width: 5rem;
    }

    &--m6plus {
        height: 4rem;
        width: 7rem;
    }
}
