/**
 * LOGIN BOX
 */

.login-box {
    color: $gray-50;
    font-size: 2rem;
    font-weight: $font-weight-bold;
    margin: 0;

    @include media-breakpoint-down(xl) {
        position: absolute;
        right: 0;
        top: 2.2rem;
        z-index: $zindex-fixed + 1;
    }
    @include media-breakpoint-up(xxl) {
        margin-left: 4.5rem;
        width: auto;
    }
    @include media-breakpoint-up(4xl) {
        margin-left: 6rem;
    }

    &__link {
        position: relative;
        display: inline-block;
        transition: color .2s ease;

        &:hover {

            &::after,
            &::before {
                width: 100%;
                left: 0;
                background: adjust-hue(map-get($theme-colors, 'm6'), 90);
            }
        }

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: calc(100% + 5px);
            width: 0;
            right: 0;
            height: 3px;
        }

        &::before {
            transition: width .3s cubic-bezier(0.51, 0.18, 0, 0.88) .05s;
            background: adjust-hue(map-get($theme-colors, 'm6'), 180);
        }

        &::after {
            transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83),
                        background .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
            background: map-get($theme-colors, 'm6');
        }
    }
}
