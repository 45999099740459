/**
 * SITE FOOTER
 */

%sub-footer-item {
    padding: 1rem 0;
    margin: 0 1.5rem;
    display: inline-block;
    color: $gray-44;

    @include media-breakpoint-up(md) {
        margin: 0 2.5rem;

        &:first-of-type:not(#a2hsBtn) {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.site-footer {
    background: $gray-11;
    font-size: 1.6rem;
    border-top: 1px solid $gray-16;

    &__wrapper {
        padding-top: $paragraph-margin;
        padding-bottom: $paragraph-margin;
        box-shadow: 0 -10px 20px rgba(0,0,0, 0.01);

        $c1 : transparentize($orange, .5);
        $c2 : transparentize($red, .3);
        $c3 : transparentize($indigo, .3);
        $c4 : transparentize($green, .5);

        border-bottom: 2px solid transparent;
        border-top: 0;
        border-image: linear-gradient(to right, $c1, $c2, $c3, $c4) 100% 1;

        &--links {
            background: $gray-16;
            border: 1px solid $gray-20;
            text-align: center;
            padding-top: $paragraph-margin;
            padding-bottom: $paragraph-margin;

            @include media-breakpoint-up(md) {
                padding-top: 1rem;
                padding-bottom: 1rem;
                text-align: inherit;
            }
        }
    }

    &__col {
        text-align: center;
        margin: 0 auto;
        margin-bottom: $paragraph-margin;
        max-width: 35rem;
    }

    &__logo {
        width: 3.6rem;
    }

    &__title {
        margin-bottom: 2.3rem;
    }

    &__text {
        font-size: 1.6rem;
        color: $gray-50;
        margin: 2.3rem 0;
    }

    &__link {
        @extend %sub-footer-item;
        cursor: pointer;

        &--push {
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    &__copyright {
        @extend %sub-footer-item;
    }
}