$push-grid-wrapper-column-1_width_tablet: percentage(515/1460); // 35.2739726027935% => 515*100/1460 (cf : maquette design)
$push-grid-wrapper-column-2_width_tablet: percentage(935/1460); // 64.0410958904% => 515*100/1460 (cf : maquette design)
$push-grid-head_width_mobile: 24.154589372vw; // => équivaut à 100px sur une largeur de 414px
$push-grid-head_width_tablet: percentage(146/514); // 28.4046692607% => 146*100/514 (cf : maquette design)
$push-grid-head_width-big_tablet: percentage(176/934); // 18.8436830835% => 176*100/934 (cf : maquette design)

.push-grid {
    &__icon::before {
        height: 100%;
        display: flex;
        align-items: center;
    }

    margin: 0 auto 8.3rem;
    max-width: 146rem;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        margin-bottom: 19.3rem;
    }

    &__header {
        margin-bottom: 5.3rem;

        @include media-breakpoint-down(md) {
            align-items: center;
            display: flex;
            flex-direction: column;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 5.5rem;
            position: relative;
        }
    }

    &__title {
        font-size: 1.8rem;
        font-weight: $font-weight-bold;
        line-height: normal;
        opacity: 0;

        @include media-breakpoint-down(md) {
            order: 1;
        }
        @include media-breakpoint-up(lg) {
            font-size: 3.2rem;
        }
        @include media-breakpoint-up(xl) {
            padding-left: 13.7rem;
            text-align: left;
        }
        @include media-breakpoint-up(xxl) {
            padding-left: 14.6rem;
        }

        &--main {
            color: $brand-color;
            display: block;
            font-size: 5rem;
            font-weight: $font-weight-light;
            line-height: 0.600; // =30px
            margin-bottom: 1.2rem;

            .letter {
                display: inline-block;
                opacity: 0;
            }

            @include media-breakpoint-down(sm) {
                font-size: 4rem;
            }

            @include media-breakpoint-up(lg) {
                font-size: 8.2rem;
                line-height: 0.378; // =31px
                margin-bottom: 2.2rem;
            }
        }

        &--secondary {
            opacity: 0;
            display: inline-block;
        }
    }

    &__brand {
        margin-bottom: 4.1rem;
        position: relative;

        @include media-breakpoint-up(lg) {
            bottom: -5.5rem;
            margin-bottom: 0;
            max-width: $push-grid-wrapper-column-2_width_tablet;
            position: absolute;
            right: 0;
            transform: translateY(100%);
            width: 100%;
        }

        &-wrap {
            display: block;
            padding-bottom: 2.5rem;
            position: relative;
            text-align: center;

            @include media-breakpoint-up(lg) {
                padding-bottom: 0;
                position: absolute;
                right: 0.1rem;
                width: $push-grid-head_width-big_tablet;
            }

            &:after {
                @include media-breakpoint-down(md) {
                    background: $black;
                    bottom: 0;
                    content: "Pro";
                    display: inline-block;
                    font-size: 1rem;
                    font-weight: $font-weight-bold;
                    left: 50%;
                    line-height: normal;
                    padding: 0.2rem 0.4rem 0;
                    position: absolute;
                    text-transform: uppercase;
                    transform: translateX(-50%);
                }
            }

            &:before {
                @include media-breakpoint-up(lg) {
                    content: "";
                    display: block;
                    padding-top: percentage(162/176);
                }
            }
        }
    }

    &__logo {
        width: 7.2rem;
        
        &--serieclub,
        &--paris-premiere {
            @include media-breakpoint-down(md) {
                width: 12rem;
            }
        }

        @include media-breakpoint-up(lg) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -60%);
        }
        @include media-breakpoint-up(xl) {
            width: 9.4rem;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        max-width: 45rem;
        margin: 0 auto;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            margin-bottom: 2.3rem;
            max-width: 100%;
        }
    }

    &__program {
        margin-bottom: $grid-gutter-width/2;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    &__content {
        display: flex;

        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }

    &__head {
        background: $gray-9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: $push-grid-head_width_mobile;
        text-align: center;

        @include media-breakpoint-up(md) {
            width: $push-grid-head_width_tablet;
        }
    }

    &__details {
        position: relative;
    }

    abbr#{&}__week {
        font-size: 2rem;
        left: 0;
        line-height: 1.700; // =34px
        position: absolute;
        text-decoration: none;
        top: -0.6rem;
        width: 100%;

        @include media-breakpoint-up(xl) {
            font-size: 3.2rem;
            line-height: 1.733; // =52px
            top: -1rem;
        }
    }

    &__day {
        display: inline-block;
        font-size: 4rem;
        font-weight: $font-weight-bold;
        line-height: 0.850; // =34px
        padding-top: 2.3rem;

        @include media-breakpoint-up(xl) {
            font-size: 6rem;
            line-height: 0.867; // =52px
            padding-top: 3.6rem;
        }
    }

    &__period {
        margin-top: 0.2rem;

        & > * {
            display: block;
            line-height: 1;
            font-size: 1.4rem;
            font-size: $font-weight-medium;
            line-height: 1.071; // =15px

            @include media-breakpoint-up(xl) {
                font-size: 2.4rem;
                line-height: 1.042; // =25px
            }
        }
    }

    abbr#{&}__month {
        line-height: 1;
        text-decoration: none;
    }

    &__visual {
        @include media-breakpoint-down(sm) {
            width: calc(100vw - #{$push-grid-head_width_mobile} - 3rem);
        }
        @include media-breakpoint-up(md) {
            width: calc(100% - #{$push-grid-head_width_tablet});
        }
    }

    // surcharge
    &__wrap {

        &--secondary {
            order: 1;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(lg) {
                order: 0;
                justify-content: space-between;
                width: $push-grid-wrapper-column-1_width_tablet;
            }

            @include media-breakpoint-down(md) {
                .push-grid__program {

                    &:first-child {
                        order: 1;
                    }
                    &:last-child {
                        order: 0;
                    }
                }
            }
        }

        &--main {
            @include media-breakpoint-up(lg) {
                margin-left: auto;
                width: $push-grid-wrapper-column-2_width_tablet;
            }

            .push-grid__head {

                @include media-breakpoint-up(lg) {
                    order: 1;
                    width: $push-grid-head_width-big_tablet;
                }
                @include media-breakpoint-up(lg) {
                    align-self: flex-end;
                    display: block; // Fix for Edge16 : bug space top
                    position: relative;

                    &::before {
                        content: "";
                        display: block;
                        padding-top: percentage(264/176);
                    }
                }
            }

            .push-grid__details {
                @include media-breakpoint-up(lg) {
                    left: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                }
            }

            abbr.push-grid__week {

                @include media-breakpoint-up(xl) {
                    font-size: 4rem;
                    line-height: 1.711; // =68px
                    top: -1.3rem;
                }
            }

            .push-grid__day {

                @include media-breakpoint-up(xl) {
                    font-size: 7.8rem;
                    line-height: 0.833; // =52px
                    padding-top: 4.2rem;
                }
            }

            .push-grid__visual {
                @include media-breakpoint-up(lg) {
                    width: calc(100% - #{$push-grid-head_width-big_tablet});
                }
            }
        }
    }

    // FOOTER
    &__footer {
        font-size: 1.7rem;
        padding-right: percentage(176/1460);

        &-text {
            margin-right: $grid-gutter-width/2;

            span {
                color: map-get($theme-colors, "m6");
            }
        }
    }

    &__social {
        list-style-type: none;

        &-item {
            &:first-child {
                margin-right: $grid-gutter-width/2;
            }
        }

        &-link {
            &:active,
            &:focus,
            &:hover {
                color: map-get($theme-colors, "m6");
            }
        }
    }
}
