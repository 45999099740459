@font-face {
  font-family: "fonticon";
  src: url('../fonts/fonticon.woff2') format('woff2'),
    url('../fonts/fonticon.woff') format('woff'),
    url('../fonts/fonticon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.icon:before {
    display: inline-block;
    font-family: "fonticon";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-audio-description:before { content: "\EA01" }
.icon-csa-10ans:before { content: "\EA02" }
.icon-csa-12ans:before { content: "\EA03" }
.icon-csa-16ans:before { content: "\EA04" }
.icon-csa-18ans:before { content: "\EA05" }
.icon-facebook:before { content: "\EA06" }
.icon-hd-tv:before { content: "\EA07" }
.icon-panel-btn:before { content: "\EA08" }
.icon-sst-sourd-malentendant:before { content: "\EA09" }
.icon-vost:before { content: "\EA0A" }
.icon-x:before { content: "\EA0B" }
