.form-control {
    border-radius: 0;
    font-weight: $font-weight-light;
    font-size: 16px;

    &:not(select):not(textarea)  {
        border-left: 0;
        border-right: 0;
        border-top: 0;
        height: auto;
    }

    &:focus {
        box-shadow: none;
    }
}

// Form group
.form-group {
    align-items: center;
}

// Checkboxes and radios
.form-check-input {
    margin-top: 0;
    top: 50%;
    transform: translateY(-50%);
}
