.date {
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    display: inline-block;
    padding: .7rem 1.8rem;

    &__day,
    &__month {
        font-size: 1.6rem;

        @at-root .date--sm & {
            font-size: 1.2rem;
        }
    }

    &__date {
        font-size: 3rem;

        @at-root .date--sm & {
            font-size: 2.4rem;
        }
    }

    &--sm {
        padding: .7rem;

        @include media-breakpoint-down(xs) {
            cursor: pointer;
        }
    }

    &--today {
        border: 1px solid $gray-44;
        border-bottom: 4px solid $brand-color;
    }
}
