.programs-grid {
    margin-left: -15px;
    margin-right: -15px;

    &__day {
        opacity: 0;

        @include media-breakpoint-up(md) {
            width: calc((100vw - 6.5rem) / 2);
        }
        @include media-breakpoint-up(lg) {
            width: calc((100vw - 6.5rem) / 3);
        }
        @include media-breakpoint-up(xl) {
            width: calc((100vw - 7rem) / 5);
        }
    }

    &__date {
        margin-bottom: 5.8rem;
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
        }
    }

    &__list {
        $c1 : transparentize($brand-color, 0.2);
        $c2 : transparentize(adjust_hue($brand-color, -30%), 0.8);
        border-image: linear-gradient(to bottom, $c1, $c2) 1 100%;
        border-right: 0;

        @include media-breakpoint-down(sm) {
            border: 0;
            padding: 0 1.5rem;
        }

        @include media-breakpoint-up(sm) {
            border-left: 0.1rem solid $brand-color;
            padding: 0 1rem;
        }

        @at-root .programs-grid__day:last-child & {
            @include media-breakpoint-up(md) {
                border-right: 0.1rem solid $brand-color;
            }
        }
    }

    &__group-title {
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
        padding: 6px 15px 6px 30px;
        margin: 2.3rem 0 0;
        background: transparentize($white, .9);
        position: relative;

        @include media-breakpoint-up(sm) {
            margin: 2.3rem -1rem 0;
        }

        @extend %square;
    }

    // .programs-grid-header
    &-header {
        position: relative;
    }

    // parties de la journée
    &__daypart {
        overflow: auto;

        &:first-child {
            display: none;

            @include media-breakpoint-down(sm) {
                display: block;

                hr {
                    margin-top: 0;
                }
            }
        }

        hr {
            border-top: 0.1rem dotted $gray-44;
            margin: 2.3rem 0;
        }

        &:after {
            @include media-breakpoint-down(sm) {
                color: $brand-color;
                display: block;
                font-size: 1.8rem;
                font-weight: $font-weight-bold;
                padding-bottom: 2.3rem;
                width: 100%;
            }
        }

        &--morning:after {
            @include media-breakpoint-down(sm) {
                content: "Matinée";
            }
        }

        &--afternoon:after {
            @include media-breakpoint-down(sm) {
                content: "Après-midi";
            }
        }

        &--evening:after {
            @include media-breakpoint-down(sm) {
                content: "Soirée";
            }
        }
    }
}
