html {
    font-size: 10px;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Body

// Typography
//h1, h2, h3, h4, h5, h6 {
//    margin-bottom: $headings-margin-bottom;
//}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.
//p {
//  margin-top: 0;
//  margin-bottom: $paragraph-margin-bottom;
//}

p {
    a {
        font-weight: bold;
        color: $body-color;

        &:hover {
            color: $white;
        }

        @at-root [data-theme="invert"] & {
            color: $gray-27;

            &:hover {
                color: $gray-16;
            }
        }
    }
}


ol,
ul,
dl {
    margin-bottom: 0;
}

// stylelint-disable font-weight-notation
b,
strong {
    font-weight: $font-weight-bold; // Add the correct font weight in Chrome, Edge, and Safari
}

// Links
a {
    color: currentColor;
    -webkit-user-drag: none;

    @include hover {
        color: currentColor;
    }
}

// Figures
figure {
    margin: 0;
}

// Button
button {
    background: none;
}
