.grid-tools {
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
    justify-content: center;
    position: relative;
    text-align: center;
    margin-bottom: 4.2rem;

    @include media-breakpoint-up(xxl) {
        margin-bottom: 0;
        position: absolute;
        right: 0;
        top: 1.5rem;
    }

    & > * {
        &:not(:last-child) {
            margin-right: 2.5rem;
        }
    }

    &__link {
        display: inline-block;
        color: $gray-75;

        &:active,
        &:focus,
        &:hover {
            color: $white;
            text-decoration: none;
        }

    }

    &__icon {
        font-size: 1.9rem;
        line-height: 1;
        margin-right: 0.9rem;
    }

    // Alerte "Dernière minute"
    .last-minute-alert {
        @include media-breakpoint-up(sm) {
            position: relative;
        }
    }

    // Popin "Dernière minute"
    .last-minute-box {

        &:before {
            @include media-breakpoint-down(xs) {
                right: 50%;
                margin-right: -9.1rem;
            }
            @include media-breakpoint-up(xxl) {
                right: 0.3rem;
            }
        }
    }
}
