
@mixin bg-gradient($color) {
    background-image:
        linear-gradient(to top,rgba(0,0,0,0) 50%,rgba(29,29,29,1) 100%),
        radial-gradient(circle farthest-side at center,hsla(hue($color),8,35,.5), transparent),
        radial-gradient(circle farthest-side at left,hsl(0,0,11), transparent 30%),
        radial-gradient(circle farthest-side at right,hsl(0,0,11), transparent 30%);
}

@mixin bg-gradient-error($color) {
    background-image: 
        linear-gradient(to bottom, rgba($gray-11,0.5) 50%, rgba($gray-11,0.94) 125%,rgba($gray-9,1) 150%),
        radial-gradient(ellipse at center, rgba($black,0) 0%, rgba($gray-11,0.9) 100%);
}