%square {
    &:before {
        background: $gray-44;
        content: "";
        height: 0.4rem;
        position: absolute;
        left: 1.7rem;
        top: 1.1rem;
        width: 0.4rem;
    }
}

.program {

    [data-content="popover"] {
        display: none;
    }

    &__link {
        display: flex;
        font-size: 1.7rem;
        line-height: normal;
        padding: 1rem 1.5rem;
        cursor: pointer;

        @include media-breakpoint-up(sm) {
            padding: .6rem 1.5rem;
            margin-left: -1rem;
            margin-right: -1rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 1.4rem;
        }

        @at-root .programs-grid__group-item & {
            background: transparentize($gray-75, .9);
            color: #cbc8c6;
        }

        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
            background: transparentize($gray-50, .9);
            outline: none;
        }
    }

    &__hour {
        font-weight: $font-weight-bold;
    }

    &__title {
        flex: 0 1 auto;
    }

    &__description {
        margin-bottom: 0;
    }

    &__visual {
        position: relative;
        width: 100%;
    }

    &__copyright {
        background: linear-gradient(0deg, rgba($black, 0.7) 0%, rgba($white, 0) 100%);
        bottom: 0;
        display: block;
        font-size: 0.9rem;
        left: 0;
        padding: 0.6rem;
        position: absolute;
        right: 0;
        text-align: right;
    }

    // Programme "simple"
    &:not(.program__push):not(.program__prime) {
        .program {

            &__hour {
                padding-right: 1.5rem;
            }
        }
    }

    // Programme de type "push"
    &--push {

        .program {

            &__hour {
                color: $brand-color;
                position: relative;
            }

            &__title {
                color: $body-color;
                max-width: calc(100% - 5.2rem); // 52px (largeur de .program__hour)

                @include media-breakpoint-down(sm) {
                    max-width: calc(100vw - (3rem + 7.3rem));
                    // **
                    // * 30px (padding bootstrap)
                    // * + 73px (largeur du .program__hour)
                }
            }

            &__visual {
                margin-top: 1.1rem;

                @include media-breakpoint-up(xl) {
                    margin-top: 0.6rem;
                }
            }
        }
    }

    // Programme de type "prime"
    &--prime {

        .program {

            &__hour {
                background: $brand-color;
                color: $white;
                order: 0;
                padding-left: 1.5rem;
                padding-bottom: 0.4rem !important;
                padding-top: 0.6rem !important;
                width: 100%;
            }

            &__title {
                color: $body-color;
                margin: 1.5rem 0;
                order: 2;
                position: relative;
                width: 100%;

                @include media-breakpoint-up(xl) {
                    margin-bottom: 1.1rem;
                    margin-top: 1.6rem;
                }
            }

            &__visual {
                order: 1;
            }

            &__caption {
                font-size: 1.6rem;
                line-height: 1.375; // =22px
                order: 3;
                flex: 0 1 auto;

                @include media-breakpoint-up(xl) {
                    font-size: 1.4rem;
                    line-height: 1.214; // =17px
                }
            }

            &__subtitle {
                font-weight: $font-weight-bold;
                margin-bottom: 0.5rem;
            }
        }
    }

    // styles commun des "push" & "prime"
    &--push,
    &--prime {
        .program {

            &__link {
                flex-wrap: wrap;
                padding: 15px;
            }

            &__title {
                font-weight: $font-weight-bold;
                text-transform: uppercase;

                @include media-breakpoint-down(sm) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
