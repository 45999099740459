$pro-btn-font-size-base: 1.5rem;
$pro-btn-font-size-lg:                ($pro-btn-font-size-base * 1.25) !default;
$pro-btn-font-size-sm:                ($pro-btn-font-size-base * .875) !default;

.btn {
    text-transform: uppercase;
    @include button-size($btn-padding-y, $btn-padding-x, $pro-btn-font-size-base, $btn-line-height, $btn-border-radius);

    [class~="ion"] {
        font-size: 20px;
        vertical-align: middle;
    }

    span:not(:only-child) {
        margin-right: 1rem;
    }
}


//
// Button Sizes
//

.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $pro-btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $pro-btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}


//
// Button Theme
//

@import "buttons.theme";