.toolbox {

    .btn {
        max-width: 22.6rem;

        &:not(:last-child) {
            margin-bottom: 1.8rem;
        }

        @include media-breakpoint-down(lg) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}