.presskit {
    text-transform: uppercase;

    &__visual {
        margin-bottom: 1.6rem;
    }

    &__flag,
    &__title {
        @extend .h4;
        margin-top: 0;
    }

    &__flag {
        color: $brand-color;
    }

    &__date {
        font-size: 1.4rem;
        font-weight: $font-weight-light;
        line-height: normal;
        margin-top: 0.8rem;
    }
}
