.aside-twitter {

    &__header {
        align-items: baseline;
        display: flex;
        padding-bottom: 1.3rem;

        @include media-breakpoint-up(xl) {
            padding-top: 4.8rem;
        }
    }

    &__icon {
        color: $twitter-color;
        font-size: 2.2rem;
        margin-right: 1.2rem;
    }

    &__title {
        margin: 0;
    }
}
