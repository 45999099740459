.site-header {

    @include media-breakpoint-up(xxl) {
        padding-top: 5.4rem;
        position: static;
    }

    &__wrapper {
        @include media-breakpoint-down(xl) {
            margin: 0 ($grid-gutter-width/2 * -1);
        }
    }

    &__main {
        padding: 3rem 0;
    }

    &__title {
        font-size: 2.2rem;
        font-weight: $font-weight-bold;
        margin: 0 auto;
        max-width: (194*100/320) + vw;
        text-align: center;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            max-width: none;
        }
    }

    &__burger {
        background: none;
        border: 0;
        color: $white;
        font-size: 3.2rem;
        line-height: 1;
        display: block;
        max-height: 8.2rem;
        min-width: 5.4rem;
        padding: 2.4rem $grid-gutter-width;
        position: absolute;
        top: 0;
        left: 0;
        z-index: $zindex-fixed + 1;

        &-icon {
            margin: 0 !important;
        }
    }

    &__content {
        @include media-breakpoint-up(xxl) {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            position: relative;
        }
    }

    &__menu {
        width: 100%;

        @include media-breakpoint-up(xxl) {
            transform: translateX(0) !important;
        }

        @include media-breakpoint-down(xl) {
            @include gradient-x($gray-11, $gray-16);
            overflow: auto;
            border-right: 1px solid $gray-11;
            padding-right: 15px;
            padding-left: 30px;
            margin-right: auto;
            margin-left: auto;
            position: fixed;
            top: 0;
            padding-top: 16px;
            padding-bottom: 84px;
            z-index: $zindex-fixed * 2;
            height: 100vh;
            width: calc(100% - 50px);
            max-width: 400px;
            transform: translateX(-100%);
        }

        @include media-breakpoint-down(xs) {
            padding-left: 15px;
        }
    }

    // Alerte "Dernière minute"
    .last-minute-alert {
        @include media-breakpoint-down(xl) {
            position: absolute;
            padding: 2.1rem 2rem;
            right: 0;
            top: 0;
        }
    }

    // Popin "Dernière minute"
    .last-minute-box {

        @include media-breakpoint-up(xxl) {
            right: -3.6rem;
        }
    }
}

.js-menu-backdrop {
    position: absolute;
    z-index: $zindex-fixed * 2 - 1;
    top: 0;
    left: 0;

    .backdrop {
        transition: background-color .3s;
        height: 100vh;
        width: 100vw;
        background: rgba(0,0,0,.6);
        display: block;
        position: fixed;
        z-index: $zindex-fixed + 1;
    }
}

//.nav-brands,
//.nav-site,
.login-box {
    display: none;
    padding: 0 $grid-gutter-width/2;

    @include media-breakpoint-up(md) {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }
    @include media-breakpoint-up(xxl) {
        display: block;
        padding: 0;
    }
}
