.js-img-overlay {
    $c1: $brand-color;
    $c2: adjust_hue($brand-color, -60deg);
    $c3: adjust_hue($brand-color, 60deg);

    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(336deg, change_color($c1, $alpha: .2), change_color($c1, $alpha: 0) 70.71%),
                linear-gradient(127deg, change_color($c2, $alpha: .2, $saturation: 10%), change_color($c2, $alpha: 0) 70.71%),
                linear-gradient(217deg, change_color($c3, $alpha: .3, $saturation: 20%), change_color($c3, $alpha: 0) 70.71%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: scale(1.2);
}

.js-img-icon {
    font-size: 25px;
    position: absolute;
    bottom: 5%;
    right: 5%;
    padding: 20px;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
}